import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from "react";

const TorchSlider = (props) => {
  const minValue = 2000;
  const maxValue = 20000;
  const step = 2000;
  const values = [...Array(maxValue/minValue)].map((_, i) => (i + 1) * minValue);
  const markStyle = { fontSize: 13, marginTop: 15, marginLeft: -10, color: 'black' };
  const marks = values.reduce((a, v) => ({ ...a, [v]: { style: markStyle, label: v}}), {});
  const style = { width: 600, marginTop: 20, marginBottom: 50, marginLeft: 25 };
  const defaultDotStyle = {
    height: 28,
    width: 28,
    marginLeft: -10
  };

  return(
    <div style={style}>
      <Slider
        min={minValue}
        max={maxValue}
        step={step}
        dots={true}
        dotStyle={{
          borderColor: '#efad7b',
          top: -9,
          ...defaultDotStyle
        }}
        activeDotStyle={{
          borderColor: '#53B78B',
          top: -9,
          ...defaultDotStyle
        }}
        marks={marks}
        onChange={props.onSliderChange}
        trackStyle={{ backgroundColor: '#53B78B', height: 10 }}
        railStyle={{ backgroundColor: 'lightcoral', height: 10 }}
        handleStyle={{
          borderColor: '#53B78B',
          marginTop: -9,
          ...defaultDotStyle
        }}
      />
    </div>
  );
}

export default TorchSlider;
