import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function Progress(props) {
  const topText = `${props.topic}`;
  const bottomText = `${props.min || 0}/${props.max || 0}`;
  const value = 100 * props.min / props.max;

  return (
    <div style={{ width: 200, height: 200}} className="bar-wrapper">
      <CircularProgressbarWithChildren
        value={value}
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 0.25,
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'butt',
          // Text size
          textSize: '10px',
          // How long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: 0.5,
          // Can specify path transition in more detail, or remove it entirely
          // pathTransition: 'none',
          // Colors
          pathColor: `rgba(83, 183, 139, ${value / 100})`,
          // textColor: 'rgb(0,0,0)',
          trailColor: '#d6d6d6',
          backgroundColor: '#53B78B',
        })}>
        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
        <div style={{ fontSize: 22 }}>
          <strong>{topText}</strong>
        </div>
        <div style={{ fontSize: 22 }}>
          {bottomText}
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}

export default Progress;
