import './SubscriptionsList.css';
import { BsFillTrashFill, BsHourglassBottom } from "react-icons/bs";
import ListPagination from './ListPagination';

const SubscriptionsListItem = (props) => {
  const { item: { status, credits, subscribedAt, unsubscribedAt } } = props;
  let itemClass = 'subscriptions_list_item';
  const isActive = status === 'active';

  if (isActive) {
    itemClass += ' subscriptions_list_item_active';
  } else {
    itemClass += ' subscriptions_list_item_inactive';
  }
  const subscribedDateString = new Date(subscribedAt).toLocaleDateString();
  const unsubscribedDateString = unsubscribedAt ? new Date(unsubscribedAt).toLocaleDateString() : 'Still active';

  return (
    <div className={itemClass}>
      <div className='subscriptions_list_item_main'>
        {isActive &&
          <div style={{display: 'block'}}>
            <div className='subscriptions_list_main_label'>{credits}</div>
            <div className='subscriptions_list_secondary_label'>monthly</div>
          </div>
        }
        {!isActive &&
          <div style={{display: 'block'}}>
            <div className='subscriptions_list_tiny_label'>no more</div>
            <div className='subscriptions_list_secondary_label subscriptions_list_secondary_label_bold'>{credits}</div>
            <div className='subscriptions_list_tiny_label'>monthly</div>
          </div>
        }
        <div style={{display: 'block'}}>
          <div className='subscriptions_list_secondary_label'>Started at:</div>
          <div className='subscriptions_list_secondary_label'>{subscribedDateString}</div>
        </div>
        <div style={{display: 'block'}}>
          <div className='subscriptions_list_secondary_label'>Finished at:</div>
          <div className='subscriptions_list_secondary_label'>{unsubscribedDateString}</div>
        </div>
      </div>
      {isActive &&
        <div style={{width: '50px', textAlign: 'center'}}>
          <button className="subscription_delete_button" onClick={(event) => {
            if (props.onDeleteSubscription) {
              // Block button
              event.currentTarget.disabled = true;
              props.onDeleteSubscription(props.item._id);
	    }
          }}>
            <BsFillTrashFill size={24} color='white'/>
          </button>
        </div>
      }
      {!isActive &&
        <div style={{width: '50px', textAlign: 'center'}}>
          <BsHourglassBottom size={24}/>
        </div>
      }
   </div>
  );
};

const SubscriptionsList = (props) => {
  return (
    <div style={{width: 'max-content'}}>
      <div style={{display: 'flex', alignItems: 'baseline', justifyContent: 'space-between'}}>
        <h2>Subscriptions</h2>
        <ListPagination pagination={props.pagination} onPageSelected={props.onPageSelected} />
      </div>
      {props.items.length > 0 &&
        <div className='subscriptions_list'>
          {props.items.map(i => <SubscriptionsListItem key={i._id} item={i} onDeleteSubscription={props.onDeleteSubscription}/>)}
        </div>
      }
      {props.items.length < 1 &&
        <div>You have no active subscriptions!</div>
      }
    </div>
  );
};

export default SubscriptionsList;
