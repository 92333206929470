import './ListPagination.css';

const ListPagination = (props) => {
  return (
    <div style={{textAlign: 'end'}}>
      {props.pagination && props.pagination.totalPages > 0 &&
        <div>
          <span>Page: </span>
          {props.pagination.page > 1 &&
            <button
              className='page-button'
              onClick={() => {
                if (props.pagination.page > 1 && props.onPageSelected) {
                  props.onPageSelected(props.pagination.page - 1);
                }
              }}>{'<'}</button>
          }
          <span>{props.pagination.page} of {props.pagination.totalPages}</span>
          {props.pagination.page < props.pagination.totalPages &&
            <button
              className='page-button'
              onClick={() => {
                if (props.pagination.page < props.pagination.totalPages && props.onPageSelected) {
                  props.onPageSelected(props.pagination.page + 1);
                }
              }}>{'>'}</button>
          }
        </div>
      }
    </div> 
  );
};

export default ListPagination;
