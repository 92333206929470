import { useState } from 'react';
import ApiKeyHolder from "../providers/ApiKeyHolder";

export default function useApiKey() {
  const getApiKey = () => {
    return ApiKeyHolder.getInstance().getApiKey();
  };

  const [apiKey, setApiKey] = useState(getApiKey());

  const saveApiKey = userApiKey => {
    ApiKeyHolder.getInstance().setApiKey(userApiKey);
    setApiKey(userApiKey);
  };

  return {
    setApiKey: saveApiKey,
    apiKey
  }
}
