import './BillingList.css';
import ListPagination from './ListPagination';
import { AiFillClockCircle, AiFillDollarCircle, AiFillCreditCard } from "react-icons/ai";

const BillingListItem = (props) => {
  const { item: { eventType, credits, happenedAt, metadata: { fromSubscription, path, method } } } = props;
  let itemClass = 'billing_list_item';
  let creditsLabel = `+${credits}`;

  let icon = (<AiFillCreditCard size={42}/>);
  let description = 'Top up';
  let meta = '(one-time)';
  if (eventType === 'top_up') {
    itemClass += ' billing_list_item_topup';
    if (fromSubscription) {
      icon = (<AiFillClockCircle size={42}/>);
      meta = '(recurring)';
    } else {
      icon = (<AiFillDollarCircle size={42}/>);
    }
  } else {
    itemClass += ' billing_list_item_spending';
    creditsLabel = `-${credits}`;
    description = 'Call ' + method.toUpperCase();
    meta = path;
  }
  const dateString = new Date(happenedAt).toLocaleDateString();
  const timeString = new Date(happenedAt).toLocaleTimeString();

  return (
    <div className={itemClass}>
      <div className='billing_list_item_main'>
        <div>{icon}</div>
        <div style={{display: 'block'}}>
          <div className='billing_list_secondary_label'>{dateString}</div>
          <div className='billing_list_secondary_label'>{timeString}</div>
        </div>
        <div style={{display: 'block'}}>
          <div className='billing_list_main_label'>{description}</div>
          <div className='billing_list_secondary_label'>{meta}</div>
        </div>
      </div>
      <div style={{display: 'block', textAlign: 'end'}}>
        <div className='billing_list_main_label'>{creditsLabel}</div>
        <div className='billing_list_secondary_label'>credits</div>
      </div>
   </div>
  );
};

const BillingList = (props) => {
  return (
    <div style={{width: 'max-content'}}>
      <div style={{display: 'flex', alignItems: 'baseline', justifyContent: 'space-between'}}>
        <h2>Billing</h2>
        <ListPagination pagination={props.pagination} onPageSelected={props.onPageSelected} />
      </div>
      {props.items.length > 0 &&
        <div className='billing_list'>
          {props.items.map(i => <BillingListItem key={i._id} item={i}/>)}
        </div>
      }
      {props.items.length < 1 &&
        <div>You have no billing information so far!</div>
      }
    </div> 
  );
};

export default BillingList;
