export default class TokenHolder {
  static getInstance() {
    if (!TokenHolder.instance) {
      TokenHolder.instance = new TokenHolder();
    }
    return TokenHolder.instance;
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }
}
