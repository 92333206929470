import { useState } from 'react';

const TorchInput = (props) => {
  const [showPassword] = useState(false);

  return(
    <div className='input-container'>
      <input
        type={showPassword || !props.password ? "text" : "password"}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
        className="input"
      />
    </div>
  );
}

export default TorchInput;