import { useState } from 'react';
import TokenHolder from '../providers/TokenHolder';

export default function useToken() {
  const getToken = () => {
    return TokenHolder.getInstance().getToken();
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    TokenHolder.getInstance().setToken(userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}
