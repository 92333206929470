import TokenHolder from './TokenHolder';

const API_URL = 'https://nft-api.torch.systems/v0/developer';

export default class ApiProvider {
  static getInstance() {
    if (!ApiProvider.instance) {
      ApiProvider.instance = new ApiProvider();
    }
    return ApiProvider.instance;
  }

  async request(method, path, body = {}, headers = {}) {
    return fetch(`${API_URL}${path}`, {
      method,
      body: body ? JSON.stringify(body) : null,
      headers
    });
  }

  async post(path, body = {}, headers = {}) {
    const response = await this.request('POST', path, body, headers);
    if (!response.ok) {
      TokenHolder.getInstance().setToken('');
      window.location.replace('/home');
      // Add here 401 handler to redirect to login page
      throw new Error('Failed to fetch!');
    }
    const responseText = await response.text();
    return JSON.parse(responseText);
  }

  async get(path, headers = {}) {
    const response = await this.request('GET', path, null, headers);
    if (!response.ok) {
      TokenHolder.getInstance().setToken('');
      window.location.replace('/home');
      // Add here 401 handler to redirect to login page
      throw new Error('Failed to fetch!');
    }
    const responseText = await response.text();
    return JSON.parse(responseText);
  }

  async delete(path, headers = {}) {
    const response = await this.request('DELETE', path, null, headers);
    if (!response.ok) {
      TokenHolder.getInstance().setToken('');
      window.location.replace('/home');
      // Add here 401 handler to redirect to login page
      throw new Error('Failed to fetch!');
    }
    const responseText = await response.text();
    return JSON.parse(responseText);
  }

  async loginOrSignUp(email, password, isSignUp) {
    const response = await this.request('POST', isSignUp ? '/signup' : '/login', { email, password }, { 'Content-Type': 'application/json' });
    const responseText = await response.text();
    return { isResponseOk: response.ok, response: JSON.parse(responseText) };
  }

  async listSubscriptionPlans() {
    const token = TokenHolder.getInstance().getToken();
    const response = this.get('/subscription_plans', { 'x-access-token': token });
    return response;
  }

  async getCheckoutUrl(subscriptionId) {
    const token = TokenHolder.getInstance().getToken();
    const result = await this.request('POST', `/subscription_plan/${subscriptionId}/subscribe`, {}, { 'x-access-token': token });
    return result;
  }

  async getDeveloperSubscription() {
    const token = TokenHolder.getInstance().getToken();
    return this.get('/subscription', { 'x-access-token': token });
  }

  async getSubscriptionPlanInfo(planId) {
    const token = TokenHolder.getInstance().getToken();
    return await this.get(`/subscription_plan/${planId}`,{ 'x-access-token': token });
  }

  async refreshApiKey() {
    const token = TokenHolder.getInstance().getToken();
    const result = await this.post(`/api_key`, {}, { 'x-access-token': token });
    return result.apiKey;
  }

  async getSubscriptions({ page = 1, pageSize = 3 }) {
    const token = TokenHolder.getInstance().getToken();
    return this.get(`/subscriptions?page=${page}&pageSize=${pageSize}`, { 'x-access-token': token });
  }

  async deleteSubscription(subscriptionId) {
    const token = TokenHolder.getInstance().getToken();
    return this.delete(`/subscription/${subscriptionId}`, { 'x-access-token': token });
  }

  async getDeveloper() {
    const token = TokenHolder.getInstance().getToken();
    return this.get('/my', { 'x-access-token': token });
  }

  async getBilling({ page = 1, pageSize = 10 }) {
    const token = TokenHolder.getInstance().getToken();
    return this.get(`/billing_credits?page=${page}&pageSize=${pageSize}`, { 'x-access-token': token });
  }

  async topUpCredits(credits, recurring = false) {
    const token = TokenHolder.getInstance().getToken();
    const result = await this.post(`/credits`, { credits, recurring }, { 'x-access-token': token, 'Content-Type': 'application/json' });
    return result.checkoutSessionUrl;
  }
}
