import React, { useState } from 'react';
import './LoginPage.css';
import PropTypes from 'prop-types';
import ApiProvider from '../providers/ApiProvider';
import validateEmail from '../utils/validate-email';
import validatePassword from '../utils/validate-password';
import TorchInput from "../components/TorchInput";

async function loginOrSignupUser({ email, password, isSignup }) {
  const result = await ApiProvider.getInstance().loginOrSignUp(email, password, isSignup);
  if (result.isResponseOk) {
    return { token: result.response.accessToken, apiKey: result.response.apiKey, error: '' };
  } else {
    return { error: result.response.error };
  }
}

export default function LoginPage({ setToken, setApiKey }) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSignup, setIsSignup] = useState(true);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [agree, setAgree] = useState(false);

  const signupClickHandler = (isSignup) => {
    setIsSignup(isSignup);
    return false;
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const checkboxHandler = () => {
    setAgree(!agree);
  }
  const loginDisabled = !validateEmail(email) || password.length < 1;
  const signupDisabled = !validateEmail(email) || !validatePassword(password)
    || !validatePassword(confirmPassword) || password !== confirmPassword
    || !agree;

  let errorMessage = error;
  if (!validateEmail(email) && email.length > 0) {
    errorMessage = 'Invalid email format!';
  } else if (isSignup && password.length > 0) {
    if (!validatePassword(password)) {
      errorMessage = 'The password is too weak!';
    } else if (password !== confirmPassword) {
      errorMessage = 'Passwords doesn\'t match!';
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const userInfo = await loginOrSignupUser({ email, password, isSignup });
    if (userInfo.error) {
      setError(userInfo.error);
    } else {
      setToken(userInfo.token);
      setApiKey(userInfo.apiKey);
    }
  }

  return(
    <div className="login-wrapper">
      <h1>{isSignup ? 'Create Developer Account' : 'Welcome back, Torch Developer!'}</h1>
      <form onSubmit={handleSubmit}>
        <TorchInput
          value={email}
          onChange={handleEmailChange}
          placeholder="Email"
          password={false}
        />
        <TorchInput
          value={password}
          onChange={handlePasswordChange}
          placeholder="Password"
          password={true}
        />
        {isSignup &&
          <TorchInput
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            placeholder="Confirm Password"
            password={true}
          />
        }
        <div>
          {errorMessage &&
            <div className='login-button-container'>
              <div className='login-error-message'>{errorMessage}</div>
            </div>
          }
          {isSignup &&
            <div>
              <input type='checkbox' id='agree' onChange={checkboxHandler} />
              <label htmlFor='agree'>I agree to <a href='https://torch.systems/terms-and-conditions/' target='_blank' rel='noreferrer'>terms and conditions</a></label>
            </div>
          }
          {!isSignup &&
            <div className='login-button-container'>
              <button type='submit' disabled={loginDisabled} className={loginDisabled ? 'login-button-disabled' : 'login-button'}>Login</button>
            </div>
          }
          {isSignup &&
            <div className='login-button-container'>
              <button type='submit' disabled={signupDisabled} className={signupDisabled ? 'login-button-disabled' : 'login-button'}>Sign Up</button>
            </div>
          }
        </div>
      </form>
      <div className='login-button-link-container'>
        {!isSignup &&
          <button className='login-button-link' onClick={() => signupClickHandler(true)}>I don't have an account</button>
        }
        {isSignup &&
          <button className='login-button-link' onClick={() => signupClickHandler(false)}>I already have an account</button>
        }
      </div>
    </div>
  )
}

LoginPage.propTypes = {
  setToken: PropTypes.func.isRequired,
  setApiKey: PropTypes.func.isRequired
}
