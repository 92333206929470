export default class ApiKeyHolder {
  static getInstance() {
    if (!ApiKeyHolder.instance) {
      ApiKeyHolder.instance = new ApiKeyHolder();
    }
    return ApiKeyHolder.instance;
  }

  setApiKey(apiKey) {
    localStorage.setItem('apiKey', apiKey);
  }

  getApiKey() {
    return localStorage.getItem('apiKey');
  }
}
