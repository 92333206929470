import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import ThanksPage from './pages/ThanksPage';
import useToken from './hooks/useToken';
import useApiKey from "./hooks/useApiKey";
import ErrorPage from "./pages/ErrorPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { token, setToken } = useToken();
  const { setApiKey } = useApiKey();

  if (!token) {
    return <LoginPage setToken={setToken} setApiKey={setApiKey}/>
  }

  return (
    <div className="wrapper">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>Hello Torch Developer</h1>
        <button className="logout-button" onClick={() => setToken(null)}>Sign Out</button>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<HomePage />}/>
          <Route path="/thanks" element={<ThanksPage />}/>
          <Route path="/error" element={<ErrorPage />}/>
          <Route path="*" element={<Navigate to="/home" replace />}/>
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={1500}/>
    </div>
  );
}

export default App;
