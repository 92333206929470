import PasswordValidator from 'password-validator';

const validatePassword = (password) => {
  const schema = new PasswordValidator();
  schema.is().min(8)
    .is().max(64)
    .has().uppercase()
    .has().lowercase()
    .has().letters()
    .has().digits()
    .has().symbols();
  return schema.validate(password);
};

export default validatePassword;
