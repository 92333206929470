import React, { useState } from 'react';
import Progress from '../components/Progress';
import TorchInput from '../components/TorchInput';
import './HomePage.css';
import ApiProvider from '../providers/ApiProvider';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useApiKey from "../hooks/useApiKey";
import ApiKeyHolder from "../providers/ApiKeyHolder";
import useToken from "../hooks/useToken";
import LoginPage from "./LoginPage";
import BillingList from '../components/BillingList';
import SubscriptionsList from '../components/SubscriptionsList';
import { AiOutlineReload, AiFillCopy } from "react-icons/ai";
import { toast } from 'react-toastify';
import TorchSlider from "../components/TorchSlider";

// function Subscription(props) {
//   return (
//     <div>
//       <div>{props.subscription.name}</div>
//       <div>{props.subscription.description}</div>
//       <Checkout subscriptionId={props.subscription._id} />
//    </div>
//   );
// }

// function SubscriptionList(props) {
//   return (
//     <div>
//       {props.subscriptions.map((s) => <Subscription subscription={s}/>)}
//     </div>
//   );
// }

async function refreshApiKey() {
  const newApiKey = await ApiProvider.getInstance().refreshApiKey();
  ApiKeyHolder.getInstance().setApiKey(newApiKey);
  window.location.replace('/home');
}

async function topUpCredits(credits, recurring) {
  const topUpLink = await ApiProvider.getInstance().topUpCredits(credits, recurring)
  window.location.replace(topUpLink);
}

async function deleteSubscription(subscriptionId, context) {
  await ApiProvider.getInstance().deleteSubscription(subscriptionId);
  const subscriptions = await ApiProvider.getInstance().getSubscriptions();
  context.setState({ subscriptions });
}

function RenderPage(props) {
  const { token, setToken } = useToken();
  const { apiKey, setApiKey } = useApiKey();
  const [isRecurring, setIsRecurring] = useState(false);
  const checkboxHandler = () => {
    setIsRecurring(!isRecurring);
  }
  const { isConfirmChangePlan, subscription, subscriptions, plan, checkoutSessionUrl, developer, billing } = props.context.state;
  if (!token) {
    return <LoginPage setToken={setToken} setApiKey={setApiKey}/>
  }

  return (
    <div>
      {!isConfirmChangePlan &&
        <div>
          <div style={{ display: "flex", flexFlow: "row wrap", gap: "5rem" }}>
            <div>
              <h2>Balance</h2>
              <div className="progress-wrapper">
                <Progress topic={'Api Credits'} min={developer.apiCredits} max={developer.apiCreditsAfterTopUp || developer.apiCredits}/>
                {/*<SubscriptionList subscriptions={this.state.subscriptions} />*/}
              </div>
            </div>
            <div>
              <SubscriptionsList
                items={subscriptions.results}
                pagination={subscriptions.paginationParams}
                onDeleteSubscription={ (subscriptionId) => deleteSubscription(subscriptionId, props.context) }
                onPageSelected={ (page) => props.context.setState({ subscriptionsPage: page }) }/>
            </div>
          </div>
          <div style={{ display: "flex", gap: "2rem" }}>
            <TorchSlider onSliderChange={(value) => props.context.setState({ topUpValue: value})}/>
            <div style={{ marginTop: 20 }}>
              <input type='checkbox' onChange={checkboxHandler} />
              <label>Recurring payment</label>
            </div>
            <button style={{ marginTop: 10 }} className="button" onClick={() => topUpCredits(props.context.state.topUpValue, isRecurring) }>Top Up</button>
          </div>
          <span>2000 credits = $2.00</span>
          <h2>Api Key</h2>
          <div style={{ display: "flex", flexFlow: "row wrap", gap: "1rem", alignItems: "center" }}>
            <TorchInput
              value={apiKey}
              onChange={() => {}}
              placeholder=""
              disabled={true}
              password={false}
            />
            <CopyToClipboard text={apiKey} onCopy={() => {
              toast('Api Key copied to clipboard!', { className: 'toast-message' });
              // props.context.setState({ copied: true });
            }}>
              <button className="button">
                <AiFillCopy size={24} color='white'/>
              </button>
            </CopyToClipboard>
            <button className="button" onClick={() => refreshApiKey()}>
              <AiOutlineReload size={24} color='white'/>
            </button>
          </div>
          <BillingList
            items={billing.results}
            pagination={billing.paginationParams}
            onPageSelected={ (page) => props.context.setState({ billingPage: page }) }/>
        </div>
      }
      {isConfirmChangePlan &&
        <div>
          <h2>Are you sure you want to change your subscription plan?</h2>
          <h3>In this case, you will lose the following balances:</h3>
          <div style={{ display: "flex", flexFlow: "row wrap", gap: "1rem", alignItems: "center" }}>
            <Progress topic={'Writes left'} min={subscription.writesLeft} max={plan.writesIncluded}/>
            <Progress topic={'Reads left'} min={subscription.readsLeft} max={plan.readsIncluded}/>
          </div>
          <div style={{ display: "flex", flexFlow: "row wrap", gap: "1rem", alignItems: "center", paddingTop: "10px" }}>
            <button className="button" onClick={() => window.location.replace(checkoutSessionUrl)}>Change the plan</button>
            <button className="button" onClick={() => props.context.setState({ isConfirmChangePlan: false })}>Leave current</button>
          </div>
        </div>
      }
    </div>
  );
}

export default class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: { results: [] },
      billing: { results: [] },
      developer: {},
      billingPage: 1,
      subscriptionsPage: 1,
      topUpValue: 2000
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    let { billingPage, subscriptionsPage } = this.state;
    if (prevState.billingPage !== billingPage || prevState.subscriptionsPage !== subscriptionsPage) {
      const [subscriptions, billing] = await Promise.all([
        ApiProvider.getInstance().getSubscriptions({ page: subscriptionsPage }),
        ApiProvider.getInstance().getBilling({ page: billingPage })
      ]);
      this.setState({ subscriptions, billing });
    }
  }

  async componentDidMount() {
    const [subscriptions, billing, developer] = await Promise.all([
      ApiProvider.getInstance().getSubscriptions({ page: this.state.subscriptionsPage }),
      ApiProvider.getInstance().getBilling({ page: this.state.billingPage }),
      ApiProvider.getInstance().getDeveloper()
    ]);
    this.setState({ subscriptions, billing, developer });
  }

  render() {
    return(<RenderPage context={ this } />);
  }
}
